import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSpecificUserEmployeeSalaryPayslipRange, getAllEmployeeSalaryPayslipRange } from "../../../actions/PaySlipAction";
import Rendertable from "./Rendertable";
import moment from "moment";

const EmployeeAttendanceList = ({
  getAllEmployeeSalaryPayslipRange,
  getSpecificUserEmployeeSalaryPayslipRange,
  employeePaysliplist,
  employeeSalarylist,
  employeeDetails,
  employee,
  department,
  from,
  to,
  data,
  setData,
  reload,
  setReload,
  loading,
  setLoading,
  componentRef,
}) => {


  useEffect(() => {
    setLoading(true);
    getAllEmployeeSalaryPayslipRange(from, from, department).then(e => {
      console.log(e)
      setData(e)
      setLoading(false);
    });
  }, [reload]);

  return (
    <>
      <div ref={componentRef}>
        <table style={{ width: "100%", }}>
          <tr >
            <td style={{ width: "100%", padding: "10px" }}>
              <h3>Famous Design LTD.</h3>
              <p>245/1-F,West Agargoan, Sher-E-Bangla nagar,Dhaka-1207.</p>
              <p>বেতন ও অতিরিক্ত কাজের সময় রশিদ </p>
              <p>মাসের নাম ও বছর : {moment(from).format("MMMM,  YYYY")}</p>
            </td>
          </tr>
        </table>
        {
          <Rendertable
            List={data}
            from={from}
            employee={employee}
            reload={reload}
            setReload={setReload}
          />
        }
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeSalarylist: state.employeePayslip.employeePayslipdetails,
    employeePaysliplist: state.employeePayslip.employeePaysliplist,
  };
};

export default connect(mapStateToProps, {
  getSpecificUserEmployeeSalaryPayslipRange,
  getAllEmployeeSalaryPayslipRange,
})(EmployeeAttendanceList);
